import React from 'react';
import { colorizer } from '../lib/colorizer';
import "../styles/pages/brands.scss";

const Brands = () => {
  return (
    <div className="rc-brands">
      <section className="rc-brands__hero">
        <div className="rc-brands__hero__inner">
          <h1>{colorizer("COMING SOON")}</h1>
        </div>
      </section>
    </div>
  );
};

export default Brands;
